/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import SEO from '../components/Seo'
import PageLayout from '../components/PageLayout'
import BannerWrapper from '../components/BannerWrapper'
import BackButton from '../components/BackButton'
import TextBlock from '../components/TextBlock'
import Banner from '../components/Banner'
import IconText from '../components/IconText'
import SummaryRow from '../components/SummaryRow'
import FreeTrialBanner from '../components/FreeTrialBanner'
import { getQuotesRowData } from '../pageData/quotes_data'

const Quotes = () => {
  const { t } = useTranslation()
  const rowData = getQuotesRowData(t) || []
  return (
    <PageLayout>
      <SEO title="Quotes" />
      <BannerWrapper>
        <BackButton />
        <Box p={[3, 3, 4, 4]}>
          <TextBlock
            heading={t('FeaturePage:featureQuotes:heading')}
            headingAsH1={true}
            textBeforeLink={t('FeaturePage:featureQuotes:detail')}
          />
        </Box>
        <Banner
          imageName="feature-man-workshop"
          imageAlt={t('ImageAlts:quotesPageAlt1')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('QuotesPage:firstBannerHeading')}
            textBeforeLink={t('QuotesPage:firstBannerDetail')}
          />
        </Banner>
        <Banner
          imageName="tablet-screen-quotes-list"
          imageAlt={t('ImageAlts:quotesPageAlt2')}
          textOrientation="right"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('Global:howItWorks')} />
            <IconText text={t('QuotesPage:listItem1')} />
            <IconText text={t('QuotesPage:listItem2')} />
            <IconText text={t('QuotesPage:listItem3')} />
            <IconText text={t('QuotesPage:listItem4')} />
            <IconText text={t('QuotesPage:listItem5')} />
            <IconText text={t('QuotesPage:listItem6')} />
          </Box>
        </Banner>
        <SummaryRow summaryData={rowData} />
        <Banner
          imageName="detail-level-floor"
          imageAlt={t('ImageAlts:quotesPageAlt3')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('QuotesPage:secondBannerHeading')}
            textBeforeLink={t('QuotesPage:secondBannerDetail')}
          />
        </Banner>
      </BannerWrapper>
      <FreeTrialBanner />
    </PageLayout>
  )
}

export default Quotes
